<template>
  <div>
    <my-nav-bar
      title="处理记录"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list v-model="loading" class="list" :finished="finished" @load="getList">
        <settle-handle-item v-for="afterOrderDiff in list" :key="afterOrderDiff.id" class="settle-handle-item" :data="afterOrderDiff" />
      </van-list>
    </div>

  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getRecords } from '@/api/settle_handle'
import SettleHandleItem from '@/views/settle-handle/components/item'
export default {
  components: { myNavBar, SettleHandleItem },
  data() {
    return {
      listQuery: {
        page: 0,
        limit: 10
      },
      list: [],
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getRecords(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 15px 15px 0 15px;
  }
  .settle-handle-item {
    margin-bottom: 15px;
  }
</style>
